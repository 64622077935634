<script lang="ts">
    import type { NewsColorSlideFragment } from '$gen/graphql';

    export let post: NewsColorSlideFragment;
</script>

<div
    class="relative m-0 grid h-full place-content-center text-2xl md:text-6xl lg:text-8xl lg:leading-loose"
    style:background={post.backgroundColor}
>
    <p class="text-center font-serif leading-tight text-white md:max-w-4xl lg:max-w-5xl">
        <a rel="external" href={post.newsLink}>
            {post.title}
        </a>
    </p>
</div>
