<script lang="ts">
    import type { NewsImageSlideFragment } from '$gen/graphql';

    import lazyimage from '$lib/actions/lazyImage';
    export let post: NewsImageSlideFragment;

    /** TS-Stuff */
    type Asset = Extract<
        NewsImageSlideFragment['newsImage'][number],
        { __typename: 'assets_Asset' }
    >;
    const isAsset = (item): item is Asset => {
        return (item as Asset).__typename === 'assets_Asset';
    };
</script>

<a href={post.newsLink} rel="external" class="relative m-0 h-full">
    {#each post.newsImage.filter(isAsset) as img}
        <img
            class="aspect-video h-full w-full object-cover"
            use:lazyimage={{ useLazyLoading: true, size: 0.95 }}
            data-src={img.url}
            data-srcset={img.srcset}
            alt={post.title}
            width={img.width}
            height={img.height}
            loading="lazy"
        />
    {/each}
</a>
