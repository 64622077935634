<script lang="ts">
    import type { BigAdFragment } from '$gen/graphql';
    import BigInserat from './BigInserat.svelte';

    export let index;
    export let ads: BigAdFragment[];
</script>

{#if index === 0}
    <div class="col-span-full -mb-5 hidden h-5 text-center text-xs lg:block xl:hidden">
        Advertisement
    </div>
{/if}
<div class="col-start-2 hidden lg:contents xl:hidden">
    {#each ads.slice(0, 2) as ad, index}
        <BigInserat {ad} />
    {/each}
    {#if ads.length === 1}
        <!-- Add a grid dummy element if there is only one ad. -->
        <div />
    {/if}
</div>
