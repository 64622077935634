<script>
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();
    const close = () => dispatch('close');

    let modal;

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            close();
            return;
        }

        if (e.key === 'Tab') {
            // trap focus
            const nodes = modal.querySelectorAll('*');
            const tabbable = Array.from(nodes).filter((n) => n.tabIndex >= 0);

            let index = tabbable.indexOf(document.activeElement);
            if (index === -1 && e.shiftKey) index = 0;

            index += tabbable.length + (e.shiftKey ? -1 : 1);
            index %= tabbable.length;

            tabbable[index].focus();
            e.preventDefault();
        }
    };
</script>

<svelte:window on:keydown={handleKeyDown} />

<div
    class="modal fixed left-1/2 top-1/3 z-modal max-h-screen w-11/12 max-w-lg -translate-x-1/2 -translate-y-1/2 overflow-visible"
    aria-modal="true"
    bind:this={modal}
>
    <div class="relative">
        <slot />
        <button on:click={close} class="absolute top-5 right-5">
            <!-- X Icon -->
            <svg class="h-8 w-9" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.07 96.45"
                ><defs
                    ><style>
                        .b {
                            fill: none;
                            stroke: #1d1d1b;
                            stroke-linecap: round;
                            stroke-miterlimit: 10;
                            stroke-width: 6px;
                        }
                    </style></defs
                ><line class="b" x1="16.65" y1="1.5" x2="79.41" y2="94.95" /><line
                    class="b"
                    x1="1.5"
                    y1="79.89"
                    x2="94.57"
                    y2="16.56"
                /></svg
            >
        </button>
    </div>
</div>
