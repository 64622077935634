<script lang="ts">
    import type { MegaAdFragment } from '$gen/graphql';
    import { routes } from '$lib/routes';

    export let ad: MegaAdFragment;
</script>

<article class="mb-5 flex flex-col">
    {#each ad.advertisingImage as banner}
        {#if banner.__typename === 'assets_Asset'}
            <div class="grid h-5 place-content-center text-xs">Advertisement</div>
            <a
                href={ad.externalLink}
                target="_blank"
                rel="external"
                on:click={async () => fetch(routes.api_countClick(ad.id))}
            >
                {#if banner.kind === 'image'}
                    <img
                        class="h-auto w-full"
                        src={banner.url}
                        alt={banner.title}
                        width={banner.width}
                        height={banner.height}
                        loading="lazy"
                    />
                {:else if banner.kind === 'video'}
                    <video
                        class="h-auto w-full"
                        src={banner.url}
                        alt={banner.title}
                        width={banner.width}
                        height={banner.height}
                        loading="lazy"
                        autoplay
                        loop
                        muted
                    />
                {/if}
            </a>
        {/if}
    {/each}
</article>

<style lang="scss">
    article {
        grid-column: full;
    }
    @media screen and (min-width: 768px) {
        article {
            grid-column: main;
        }
    }
</style>
