<script lang="ts">
    import type { KubaparisOverlayVideosFragment } from '$gen/graphql';
    import { isVideoAsset } from '$lib/api/shared/fragments';
    import { onMount } from 'svelte';
    import { fade } from 'svelte/transition';

    export let STORAGE_KEY: string;
    export let videos: KubaparisOverlayVideosFragment;

    let playMode: PlayMode = 'play';
    let showSkip: boolean = false;

    const setCookie = (value: PlayMode) => {
        document.cookie = `${STORAGE_KEY}=${value}; SameSite=Strict`;
    };

    onMount(() => {
        const timeout = setTimeout(() => (showSkip = true), 2000);
        return () => {
            if (timeout) clearTimeout(timeout);
        };
    });

    function videoEnded() {
        console.log('video finished');
        playMode = 'played_already';
        setCookie('played_already');
    }
</script>

{#if playMode === 'play'}
    <div
        on:click={videoEnded}
        out:fade|local={{ duration: 300 }}
        class="fixed top-0 bottom-0 left-0 right-0 z-video-overlay w-screen cursor-pointer"
    >
        <!-- LIGHT MODE VIDEOS -->
        <div class="grid h-full w-full items-center object-cover dark:hidden">
            <!-- DESKTOP VIDEO -->
            {#if isVideoAsset(videos?.desktop[0])}
                <video
                    on:ended={videoEnded}
                    style:background-color={videos.backgroundColor}
                    class="hidden min-h-screen min-w-[100vw] object-cover md:block"
                    src={videos.desktop[0].url}
                    autoplay
                    muted
                    loading="eager"
                    playsinline
                >
                    <track kind="captions" value="Animated Kubaparis Logo." />
                </video>
            {/if}
            <!-- MOBILE VIDEO -->
            {#if isVideoAsset(videos?.mobile[0])}
                <video
                    on:ended={videoEnded}
                    style:background-color={videos.backgroundColor}
                    class="block min-h-screen min-w-[100vw] object-cover md:hidden"
                    src={videos.mobile[0].url}
                    autoplay
                    muted
                    loading="eager"
                    playsinline
                >
                    <track kind="captions" value="Animated Kubaparis Logo." />
                </video>
            {/if}
        </div>

        <!-- DARK MODE VIDEOS -->
        <div class="hidden h-full w-full items-center object-cover dark:grid">
            <!-- DESKTOP VIDEO DARK -->
            {#if isVideoAsset(videos?.desktopDarkMode[0])}
                <video
                    on:ended={videoEnded}
                    style:background-color={videos.backgroundColorDarkMode}
                    class="hidden min-h-screen min-w-[100vw] object-cover md:block"
                    src={videos.desktopDarkMode[0].url}
                    autoplay
                    muted
                    loading="eager"
                    playsinline
                >
                    <track kind="captions" value="Animated Kubaparis Logo." />
                </video>
            {/if}

            <!-- MOBILE VIDEO DARK -->
            {#if isVideoAsset(videos?.mobileDarkMode[0])}
                <video
                    on:ended={videoEnded}
                    style:background-color={videos.backgroundColorDarkMode}
                    class="block min-h-screen min-w-[100vw] object-cover md:hidden"
                    src={videos.mobileDarkMode[0].url}
                    autoplay
                    muted
                    loading="eager"
                    playsinline
                >
                    <track kind="captions" value="Animated Kubaparis Logo." />
                </video>
            {/if}
        </div>

        {#if showSkip}
            <button
                transition:fade|local
                class="absolute bottom-20 left-1/2 -translate-x-1/2 text-sm text-white opacity-50"
                on:click
            >
                SKIP
            </button>
        {/if}
    </div>
{/if}
