<script lang="ts">
    import type { EmblaCarouselType } from 'embla-carousel';

    import EmblaCarousel from 'embla-carousel';
    import { onMount } from 'svelte';
    import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
    import type { OptionsType } from 'embla-carousel/components/Options';
    import NewsSliderImage from './NewsSliderImage.svelte';
    import NewsSliderText from './NewsSliderText.svelte';
    export let lazy = true;

    export let slides: NewsPosts = [];
    export let currentIndex = 0;

    let emblaNode: HTMLDivElement;
    let embla: EmblaCarouselType;
    let canScrollNext: boolean;
    let canScrollPrev: boolean;

    $: if (embla) handleChange(); // init prev and next buttons
    $: if (emblaNode) {
        innerHeight = emblaNode.offsetHeight;
    }
    onMount(() => {
        const options: Partial<OptionsType> = { loop: true, align: 'center' };
        embla = EmblaCarousel(emblaNode, options, [WheelGesturesPlugin()]);
        embla.on('select', handleChange);
        return () => {
            if (embla) embla.destroy();
        };
    });

    function handleChange() {
        canScrollNext = embla.canScrollNext();
        canScrollPrev = embla.canScrollPrev();
        currentIndex = embla.selectedScrollSnap();
    }

    export function setIndex(index: number) {
        embla.scrollTo(index);
    }
</script>

<section class="contents">
    <div
        class="relative col-span-1 -ml-5 w-[calc(100%+2.5rem)] md:m-0 md:w-full lg:col-span-2 xl:col-span-3"
        style={`cursor: ${slides.length > 1 ? 'grab' : 'default'}`}
    >
        <!-- embla-viewport -->
        <div
            bind:this={emblaNode}
            class="embla__viewport h-full max-h-full overflow-hidden bg-transparent"
        >
            <!-- embla-container -->
            <div class="embla__container grid h-full auto-cols-[100%] grid-flow-col">
                {#each slides as post}
                    <!-- embla-slide -->
                    <div
                        class="embla__slide relative m-0 aspect-4/3 max-h-full xl:aspect-news-slider"
                    >
                        {#if post.__typename === 'news_color_Entry'}
                            <NewsSliderText {post} />
                        {:else if post.__typename === 'news_image_Entry'}
                            <NewsSliderImage {post} />
                        {/if}
                    </div>
                {/each}
            </div>
        </div>
        {#if slides.length > 1}
            {#if canScrollPrev}
                <button
                    id="prev"
                    class="absolute top-0 hidden h-full w-1/5 cursor-w-resize p-5 uppercase md:block md:text-2xl"
                    on:click={() => embla.scrollPrev()}
                    disabled={!canScrollPrev}
                    class:disabled={!canScrollPrev}
                >
                    <!-- <div class="icon">Back</div> -->
                </button>
            {/if}
            {#if canScrollNext}
                <button
                    id="next"
                    class="absolute top-0 right-0 hidden h-full w-1/5 cursor-e-resize p-5 uppercase md:block md:text-2xl"
                    on:click={() => embla.scrollNext()}
                    disabled={!canScrollNext}
                    class:disabled={!canScrollNext}
                >
                    <!-- <div class="icon">Next</div> -->
                </button>
            {/if}
        {/if}
        {#if slides && slides.length > 0}
            <div
                class="absolute bottom-1 right-2 space-x-3 font-serif text-xs text-white md:bottom-4 md:right-5 md:text-xl"
            >
                <span>News</span><span>{currentIndex + 1}/{slides.length}</span>
            </div>
        {/if}
    </div>
</section>

<style lang="scss">
    section {
        grid-column: main;
    }
</style>
