<script lang="ts">
    import { localStore } from '$lib/privacy-banner/localStore';

    import Modal from './Modal.svelte';

    export let open = false;
</script>

{#if open}
    <Modal
        on:close={() => {
            open = false;
            $localStore.showNewsletterModal = false;
        }}
    >
        <!-- Begin Mailchimp Signup Form -->
        <div
            id="mc_embed_signup"
            class="rounded-3xl bg-yellow p-5 text-xl text-black shadow-xl md:rounded-[4rem] md:px-10 md:py-16"
        >
            <form
                action="https://kubaparis.us3.list-manage.com/subscribe/post?u=e6c2e760cd8ce8dce7fc1a643&amp;id=0aea809baa"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                class="validate "
                target="_blank"
                novalidate
            >
                <div id="mc_embed_signup_scroll">
                    <h2 class="mb-16 mr-8">
                        Weekly News from KUBAPARIS?<br />Subscribe to our Newsletter.
                    </h2>
                    <div class="flex flex-col gap-1 md:flex-row md:border-b-2">
                        <div class="mc-field-group grow">
                            <!-- <label for="mce-EMAIL">Email <span class="asterisk ">*</span> </label> -->
                            <input
                                type="email"
                                value=""
                                placeholder="E-mail address"
                                name="EMAIL"
                                class="required email w-full border-b-2 bg-inherit placeholder:text-black md:border-none"
                                id="mce-EMAIL"
                            />
                        </div>
                        <div id="mce-responses" class="clear">
                            <div class="response" id="mce-error-response" style="display:none" />
                            <div class="response" id="mce-success-response" style="display:none" />
                        </div>
                        <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                        <div style="position: absolute; left: -5000px;" aria-hidden="true">
                            <input
                                type="text"
                                name="b_e6c2e760cd8ce8dce7fc1a643_0aea809baa"
                                tabindex="-1"
                                value=""
                            />
                        </div>
                        <div class="clear shrink">
                            <input
                                type="submit"
                                value="subscribe"
                                name="subscribe"
                                id="mc-embedded-subscribe"
                                class="button"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <!--End mc_embed_signup-->
    </Modal>
{/if}
